import {Dispatch, SetStateAction} from "react";

export interface IAppContext {
  setShowSideBar: Dispatch<SetStateAction<boolean>>,
  showSideBar: boolean
}

export const InitialAppContext: IAppContext = {
  setShowSideBar: () => {},
  showSideBar: false
}