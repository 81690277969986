import {Card, CardBody, CardHeader} from "@material-tailwind/react";

const RightSide = () => {
  const avxtEpisode1 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/avxtEpisode1.png'
  const avxtEpisode3 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/avxtEpisode3.png'
  const couchSurfing = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/couchSurfing.png'
  const couchSurfing1 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/couchSurfing1.png'
  const couchSurfing2 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/couchSurfing2.png'
  const couchSurfing3 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/couchSurfing3.png'
  const englishClub = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/englishClub.png'
  const englishClub1 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/englishClub1.png'
  const amesEnglish = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/amesEnglish.png'
  const langtreSOS = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/langtreSOS.png'

  return (
      <>
        <Card className="h-full w-full overflow-scroll bg-[#232D3F]">
          <CardHeader
              floated={false}
              shadow={false}
              className="m-0 rounded-none border-b border-blue-gray-100 bg-[#005B41] text-white font-bold p-3"
          >
            There are some highlights from my activities to contribute to the community
          </CardHeader>
          <CardBody>
            <p className='text-sm md:text-lg font-bold text-white' id='activity1'>I was an examiner in Thanh Hoa’s
              competition for high school students and presented on TV</p>
            <a
                className='underline underline-offset-4 text-customGreen mb-3'
                href="https://www.youtube.com/watch?v=hGMVusf32jw"
                target="_blank"
                rel="noopener noreferrer"
            >Bruce and Mike Am vang xu Thanh episode 1 - YouTube</a>
            <img src={avxtEpisode1} alt="" className="mb-3 rounded-xl"/>
            <a
                className='underline underline-offset-4 text-customGreen mb-3'
                href="https://www.youtube.com/watch?v=2cqqWzpRPbQ&list=PLb7eftskPYL9gSlnfz21qeWZ2LUfATTYk"
                target="_blank"
                rel="noopener noreferrer"
            >Bruce and Derrick on TV episode 3 - YouTube</a>
            <img src={avxtEpisode3} alt="" className="mb-3 rounded-xl"/>
            <p className='font-bold text-sm md:text-lg text-white' id='activity2'>As a brand director , I was a
              presentative for my English Center AMES on the local Channel:</p>
            <a
                className='underline underline-offset-4 text-customGreen mb-3'
                href="https://www.facebook.com/AnhNguAMESThanhHoa/videos/187760858562422"
                target="_blank"
                rel="noopener noreferrer"
            >Facebook Link</a>
            <img src={amesEnglish} alt="" className="rounded-xl"/>
            <p className='font-bold text-sm md:text-lg text-white mt-3'>OTHER SOCIAL ACTIVITIES :</p>
            <p className='text-blue-gray-100 mb-3'>I was involved in many socical activities which contributed to our
              soceity and communities in
              particular .</p>
            <p className='font-bold text-sm md:text-lg mb-3 text-white' id='activity3'>1. Taught English to the orphans
              at
              SOS center in Thanh Hoa City</p>
            <img src={langtreSOS} alt=""/>
            <p className='font-bold text-sm md:text-lg mt-3 text-white' id='activity4'>2. Was a founder of Thanh Hoa
              English Club which held weekly meetings for students to have an
              opportunities to practice their English with native speakers</p>
            <a
                className='underline underline-offset-4 text-customGreen mb-3'
                href="https://www.facebook.com/englishclubthanhhoa"
                target="_blank"
                rel="noopener noreferrer"
            >Facebook: Thanh Hoa English Club</a>
            <img src={englishClub} alt=""/>
            <img src={englishClub1}/>
            <p className='font-bold text-sm md:text-lg mt-3 text-white' id='activity5'>3. Was a founder of Couchsurfing
              Thanh Hoa which supports foreigners with free accommodation
              and travelling tips and guides</p>
            <a
                className='underline underline-offset-4 text-customGreen mb-3'
                href="https://www.youtube.com/watch?v=4j7FHCVlV70"
                target="_blank"
                rel="noopener noreferrer"
            >Thanh Hoa CouchSurfing</a>
            <img src={couchSurfing} alt="" className="mb-3 rounded-xl"/>
            <img src={couchSurfing1} alt="" className="mb-3 rounded-xl"/>
            <img src={couchSurfing2} alt="" className="mb-3 rounded-xl"/>
            <img src={couchSurfing3} alt="" className="mb-3 rounded-xl"/>
          </CardBody>
        </Card>
      </>
  )
}

export default RightSide
