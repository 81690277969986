import React, {useCallback, useState} from 'react';

interface IMediaProps {
  image: string,
  images: string[]
}

const Media = (props: IMediaProps) => {
  const [imagesSelected, setImagesSelected] = useState<string[]>(props.images);
  const [image, setImage] = useState<string>(props.image);
  const [count, setCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  
  const hoverImageProduct = useCallback((hoveredImage: string) => {
    setImage(hoveredImage);
    setCount((count) => count + 1);
    setIsHovered(true);
  }, []);
  
  function ShowImage(index: number, imageShowed: string): React.ReactElement {
    return (
      <div className="rounded h-20 relative mr-4" key={index}>
        <img
          src={imageShowed}
          className={`h-full cursor-pointer w-full'
						${imageShowed === image
            ? 'border-red-700 border-solid border-2'
            : 'border-slate-900 border-solid border'
          }`}
          alt=""
          key={index}
          onClick={() => hoverImageProduct(imageShowed)}
        />
      </div>
    );
  }
  
  const ShowMainImage = useCallback(() => {
    return (
      <div className="flex justify-center">
        <img
          src={image}
          className="responsive"
          alt=""
          // style={{height: 500}}
        />
      </div>
    );
  }, [image, imagesSelected]);
  
  const ShowingImages = (
    <>
      {ShowMainImage()}
      <div className="flex w-full overflow-auto py-4 whitespace-nowrap">
        {imagesSelected.map((image, index) => ShowImage(index, image))}
      </div>
    </>
  );
  
  return (
    <>
      {ShowingImages}
    </>
  );
};

export default Media;
