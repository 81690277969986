import {useState} from "react";
import {SampleProjectComponent} from "./SampleProjectComponent";

export function GiftCard() {
  const giftCard01 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard01.png'
  const giftCard02 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard02.png'
  const giftCard03 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard03.png'
  const giftCard04 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard04.png'
  const giftCard05 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard05.png'
  const [image, setImage] = useState(giftCard01)
  const [images, setImages] = useState([giftCard01, giftCard02, giftCard03, giftCard04, giftCard05])
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(open => !open)
  }
  const detail = (
    <>
      <button onClick={() => toggleOpen()}>
        <p className="font-bold text-sm text-customGreen">What I did in the project: (Click to see full details)</p>
      </button>
      {open &&
          <div className="grid gap-1 mt-2">
              <p className="font-bold text-sm">Regarding the Frontend: I used React Typescript and Polaris Library</p>
              <p className="text-sm">1. I created UI UX for Product, Theme extension and Translation pages</p>
              <p className="text-sm">2. I set up API call from frontend to the backend for Product Page</p>
              <p className="text-sm">3. I set up some important parts used for the whole project : </p>
              <p className="text-sm">a. Created Routes component: using React Router Dom</p>
              <p className="text-sm">b. Set up API call hook: using React Query</p>
              <p className="text-sm">c. Created store folder for handling global variables using useContext and useReducer</p>
              <p className="font-bold text-sm">Regarding the backend: I used NestJs </p>
              <>1. I created Product Creator and Product Editor api call with NestJS</>
          </div>}
    
    </>
  )
  
  const TABLE_ROWS = [
    {
      name: "Overview",
      detail: "This is a Shopify app for merchants to create Gift Card products",
    },
    {
      name: "Estimated Cost",
      detail: "3 months",
    },
    {
      name: "Programming Languages",
      detail: "React for Frontend ( using Polaris library ), NestJS for Backend ",
    },
    {
      name: "Database",
      detail: "MongoDB",
    },
    {
      name: "Tools and IDE",
      detail: "Visual Code && IntelliJ",
    },
    {
      name: "Some details",
      detail: detail,
    },
    {
      name: "Source Code",
      detail: "Unable to publish because this is our company product",
    },
    {
      name: "Final Product",
      detail: <a href="https://apps.shopify.com/vify-giftcard" className="underline underline-offset-4 text-customGreen">Vify:
        Professional Gift Cards</a>,
    },
  ];
  return (
    <>
      <>
        <SampleProjectComponent image={image} images={images} tableRows={TABLE_ROWS} />
      </>
    </>
  );
}
