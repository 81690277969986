import {ActionTypes, AppState} from "./types";

export const toggleShowMenu = ( payload: AppState) => ({
  type: ActionTypes.SHOW_MENU,
  payload
})

export const setPageName = ( payload: AppState) => ({
  type: ActionTypes.SET_PAGE,
  payload
})
