import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {useContext, useEffect} from "react";
import AppContext from "../../../store/Context";
import {setPageName} from "../../../store/actions";

const Resume = () => {
  const {state, dispatch} = useContext(AppContext)
  useEffect(() => {
    dispatch(setPageName({...state,page: 'resume'}))
  },[])

  return (
    <div className="flex justify-center">
      <div className="max-w-full md:w-4/5 md:my-5 rounded-md">
        <div className="md:grid md:grid-cols-3 md:gap-4">
          <div className=" pb-5 md:col-span-1 hidden md:block ">
            <LeftSide />
          </div>
          <div className="md:col-span-2 mx-0 md:mx-5 ">
            <RightSide />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resume
