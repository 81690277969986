import {useState} from "react";
import classNames from "classnames";

const LeftSide = () => {
  const [activeButton,setActiveButton] = useState('activity1')
  const baseClasses = 'text-start block hover:text-white text-gray-300 py-2 px-4 cursor-pointer font-sans text-sm underline-animation'
  const activeClasses = 'activeClasses';

  return (
    <>
      <ul className="flex flex-col">
        <li className="flex-1 mr-2">
          <a className={classNames(baseClasses,{[activeClasses]: activeButton === 'activity1'})}
             onClick={() => {
               setActiveButton('activity1')
               window.location.replace("#activity1")
             }}>EXAMINER FOR HIGH SCHOOL&apos;S COMPETITION</a>
        </li>
        <li className="flex-1 mr-2 mt-2" >
          <a className={classNames(baseClasses,{[activeClasses]: activeButton === 'activity2'})}
             onClick={() => {
               setActiveButton('activity2')
               window.location.replace("#activity2")
             }} >BEING A REPRESENTATIVE FOR AMES ENGLISH</a>
        </li>
        <li className="flex-1 mr-2 mt-2">
          <a className={classNames(baseClasses,{[activeClasses]: activeButton === 'activity3'})}
             onClick={() => {
               setActiveButton('activity3')
               window.location.replace("#activity3")
             }}>TAUGHT ENGLISH AT SOS CENTER</a>
        </li>
        <li className="flex-1 mr-2 mt-2">
          <a className={classNames(baseClasses,{[activeClasses]: activeButton === 'activity4'})}
             onClick={() => {
               setActiveButton('activity4')
               window.location.replace("#activity4")
             }}>BEING A FOUNDER OF ENGLISH CLUB</a>
        </li>
        <li className="flex-1 mr-2 mt-2">
          <a className={classNames(baseClasses,{[activeClasses]: activeButton === 'activity5'})}
             onClick={() => {
               setActiveButton('activity5')
               window.location.replace("#activity5")
             }}>BEING A FOUNDER OF TH&apos;S COUCH SURFING</a>
        </li>
      </ul>
    </>
  )
}

export default LeftSide
