import {createContext, useContext, useState} from "react";
import {IAppContext, InitialAppContext} from "../types/AppContext";

export const AppContext = createContext<IAppContext>(InitialAppContext)

const AppProvider = ({children}: any) => {
  const [showSideBar, setShowSideBar] = useState(false)

  return <AppContext.Provider value={{
    setShowSideBar,
    showSideBar
  }}>
    {children}
  </AppContext.Provider>
}

export default AppProvider

export const useApp = () => {
  return useContext(AppContext)
}