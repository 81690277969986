import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider, Outlet, redirect} from 'react-router-dom';
import MenuBar from './components/MenuBar';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import AboutMe from './pages/aboutMe';
import Adelaide from './pages/australia/Adelaide'
import ExtraActivities from './pages/aboutMe/extraActivities/index';
import Resume from './pages/aboutMe/resume'
import MyProjects from './pages/aboutMe/projects/index';
import Login from '../src/pages/login/index'
import Register from "./pages/register";
import {useTokenContext} from './context/TokenProvider'
import {SideBar} from "./components/SideBar";
import AppProvider from "./context/AppProvider";

function Root() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
        <SideBar />
        <MenuBar />
        <Outlet/>
        </AppProvider>
      </QueryClientProvider>
    </>
  );
}


export default function App() {
  const data = useTokenContext()
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Root />,
        children: [
          {
            path: '/',
            element: <AboutMe/>
          },
          {
            path: '/about-me',
            element: <AboutMe/>
          },
          {
            path: '/extra-activities',
            element: <ExtraActivities/>
          },
          {
            path: '/my-projects',
            element: <MyProjects/>,
          },
          {
            path: '/resume',
            element: <Resume/>
          },
          {
            path: '/australia',
            element: <Adelaide/>
          },
          {
            path: '/login',
            element: <Login />
          },
          {
            path: '/register',
            element: <Register/>
          }
        ]
      },
    ],
  );
  return <RouterProvider router={router}/>;
}
