import { Card, Typography } from "@material-tailwind/react";
import {useState} from "react";
import {SampleProjectComponent} from "./SampleProjectComponent";
 
export function Portfolio() {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(open => !open)
  }
  
  const detail = (
    <>
      <button onClick={() => toggleOpen()}>
        <p className="font-bold">What I did in the project:  (Click to see full details)</p>
      </button>
      {open &&
          <div className="grid gap-1 mt-2">
              <p className="font-bold">Regarding the Frontend: I used React Typescript and Material-Tailwind Library</p>
              <p>(I am in favour of Material Design and Tailwind css)</p>
              <p>1. I created Log-in and Register features for future usage </p>
              <p>for example : I will sell some online courses</p>
              <p>3. I set up some important parts used for the whole project : </p>
              <p>a. Created Routes component: using CreateBrowserRouter from React Router Dom</p>
              <p>b. Set up API call hook: using React Query</p>
              <p>c. Created store folder for handling global variables using useContext and useReducer</p>
              <p className="font-bold">Regarding the backend: I used NodeJs </p>
              <p>(Due to the short period of time, I was able to make my backend as simple as possible)</p>
              <p>1. I used JWT for create Token and handle Token</p>
              <p>2. I used bcrypt to hash passwords</p>
              <p>3. I handled CORS errors to be able make API calls from frontend to backend</p>
              <p>3. I used bcrypt to hash passwords</p>
              <p className="font-bold">Regarding the Server: I used AWS</p>
              <p>1. I registered an EC2 instance ( using Ubuntu OS) to host my project</p>
              <p>2. I registered a domain with Route 53 </p>
              <p>3. I also used S3 to store some images and data</p>
              <p>4. I used EBS, Elastic IPs, Load Balancers</p>
          </div> }
    
    </>
  )
  
  const TABLE_ROWS = [
    {
      name: "Overview",
      detail: "This is my personal project. I am going to use this website for a long-term period",
    },
    {
      name: "Estimated Cost",
      detail: "14 days",
    },
    {
      name: "Programming Languages",
      detail: "React for Frontend, NodeJS for Backend ",
    },
    {
      name: "Database",
      detail: "MongoDB",
    },
    {
      name: "Tools and IDE",
      detail: "IntelliJ",
    },
    {
      name: "Some details",
      detail: detail,
    },
    {
      name: "Source Code",
      detail: "Will be published on personal purposes only",
    },
    {
      name: "Final Product",
      detail: <a href="/" className="underline underline-offset-4 text-customGreen">My Portfolio</a>,
    },
  ];
  
  
  return (
    <SampleProjectComponent tableRows={TABLE_ROWS} />
  );
}
