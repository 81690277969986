import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import { useForm,SubmitHandler } from "react-hook-form";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import {useTokenContext} from "../../context/TokenProvider";
import Cookies from "universal-cookie";

interface IFormInput {
  name: string;
  email: string,
  password: string,
  isReceivedEmail: boolean
}

const Register = () => {
  const navigate = useNavigate()
  const tokenProvider = useTokenContext()
  const cookies = new Cookies()
  const { register, handleSubmit } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
      axios(
        {
          method:'POST',
          url: 'http://localhost:3000/register',
          data: {
            email: data.email,
            password: data.password
          }
        }
      )
      .then((response) => {
        if (response) {
          cookies.set("TOKEN", response.data.token, {
            path: "/",
          });
          const newToken = tokenProvider.getToken()
          tokenProvider.setToken(newToken)
          navigate('/about-me')
        }
      })
      .catch((e) => console.log(e))
  };
  
  return (
    <div className="flex justify-center mt-5">
      <Card color="transparent" shadow={false}>
        <Typography variant="h4" color="blue-gray">
          Sign Up
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Nice to meet you! Enter your details to register.
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-1 flex flex-col gap-6">
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Your Name
            </Typography>
            <Input
              size="lg"
              crossOrigin="true"
              placeholder="name@mail.com"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              {...register('name')}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Your Email
            </Typography>
            <Input
              size="lg"
              placeholder="name@mail.com"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin="true"
              {...register('email')}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Password
            </Typography>
            <Input
              type="password"
              size="lg"
              placeholder="********"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin="true"
              {...register('password')}
            />
          </div>
          <Checkbox
            crossOrigin
            label={
              <Typography
                variant="small"
                color="gray"
                className="flex items-center font-normal"
              >
                I agree to
                <a
                  href="#"
                  className="font-medium transition-colors hover:text-gray-900"
                >
                  &nbsp;Receive email notifications
                </a>
              </Typography>
            }
            containerProps={{ className: "-ml-2.5" }}
            {...register('isReceivedEmail')}
          />
          <Button className="mt-6" fullWidth onClick={handleSubmit(onSubmit)}>
            sign up
          </Button>
          <Typography color="gray" className="mt-4 text-center font-normal">
            Already have an account?{" "}
            <a href="/login" className="font-medium text-gray-900">
              Sign In
            </a>
          </Typography>
        </form>
      </Card>
    </div>
  );
}

export default Register
