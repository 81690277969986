import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import {useContext} from "react";
import AppContext from "../../../store/Context";

const ExtraActivities = () => {
  const {state} = useContext(AppContext)
  
  return (
    <div className="md:flex justify-center">
      <div className="max-w-full md:w-4/5 md:my-5 ">
        <div className="md:grid md:grid-cols-3 md:gap-4">
          {state.showMenu && <div className="h-30 md:h-screen bg-gray-300 sticky top-14 md:hidden pt-2 pb-4 lg:hidden">
              <LeftSide/>
          </div>}
          <div className="h-30 md:h-screen sticky top-14 hidden md:block pt-2 pb-4 lg:block">
            <LeftSide/>
          </div>
          <div className="md:col-span-2 md:mx-5 grid grid-flow-row gap-4">
            <RightSide/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraActivities
