import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";
import { useForm,SubmitHandler } from "react-hook-form";
import axios from 'axios'
import {useNavigate} from "react-router-dom";
import Cookies  from 'universal-cookie'
import {useTokenContext} from "../../context/TokenProvider";
import {calculateNewValue} from "@testing-library/user-event/dist/utils";

interface IFormInput {
  email: string,
  password: string,
}

const Login = () => {
  const tokenProvider = useTokenContext()
  const cookies = new Cookies()
  const navigate = useNavigate()
  const { register, handleSubmit } = useForm<IFormInput>();
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data)
    axios(
      {
        method:'POST',
        url: 'http://localhost:3000/login',
        data: {
          email: data.email,
          password: data.password
        }
      }
    )
      .then((response) => {
        if (response) {
          cookies.set("TOKEN", response.data.token, {
            path: "/",
          });
          const newToken = tokenProvider.getToken()
          tokenProvider.setToken(newToken)
          navigate('/my-projects')
        }
      })
      .catch((e) => console.log(e))
  };
  
  return (
    <div className="flex justify-center mt-5">
      <Card color="transparent" shadow={false}>
        <Typography variant="h4" color="blue-gray">
          Sign In
        </Typography>
        <Typography color="gray" className="mt-1 font-normal">
          Nice to see you again! Enter your details to log in.
        </Typography>
        <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-1 flex flex-col gap-6">
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Your Email
            </Typography>
            <Input
              size="lg"
              placeholder="name@mail.com"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin="true"
              {...register('email')}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Password
            </Typography>
            <Input
              type="password"
              size="lg"
              placeholder="********"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
              crossOrigin="true"
              {...register('password')}
            />
          </div>
          <Button className="mt-6" fullWidth onClick={handleSubmit(onSubmit)}>
            sign in
          </Button>
          <Typography color="gray" className="mt-4 text-center font-normal">
            Do not have an account?{" "}
            <a href="/register" className="font-medium text-gray-900">
              Sign Up
            </a>
          </Typography>
        </form>
      </Card>
    </div>
  );
}

export default Login
