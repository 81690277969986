import React from "react";
import {
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  IdentificationIcon
} from "@heroicons/react/24/solid";

export default function AboutMeMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // nav list menu
  const otherLanguagesMenuItems = [
    {
      title: "Introducing myself",
      description:
        "The first impression of me",
      href: "about-me"
    },
    {
      title: "My Resume",
      description:
        "All work experience",
      href: 'resume'
    },
    {
      title: "My Projects",
      description: 'My completed Projects in details',
      href: 'my-projects'
    },
    {
      title: "My Community Service activities",
      description: 'What I have contributed to my Soceity',
      href: 'extra-activities'
    },
  ];

  const renderItems = otherLanguagesMenuItems.map(({ title, description, href }) => (
    <a href={href} key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));

  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="about-me" variant="small" className="font-normal">
            <MenuItem className="hidden items-center gap-2 font-medium text-blue-gray-900 lg:flex lg:rounded-full">
              <IdentificationIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
              About Me{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-7 gap-3 overflow-visible lg:grid">
          <Card
            color="blue"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md"
          >
            <IdentificationIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 font-medium text-blue-gray-900 lg:hidden">
        <IdentificationIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
        Pages{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}
