import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from "@material-tailwind/react";
import TokenProvider from "./context/TokenProvider";
import AppProvider from "./store/AppProvider";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AppProvider>
        <TokenProvider>
          <App/>
        </TokenProvider>
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();

