import Introduction from "./Introduction"

const AboutMe = () => {

	return (
		<div className="mx-5 mt-5">
			<Introduction />
		</div>
	)
}

export default AboutMe