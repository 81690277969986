import {Dispatch} from "react";

export interface AppState {
  showMenu: boolean,
  page: string
}
export interface IAppContext  {
  state: AppState
  dispatch: Dispatch<AppAction>
}

export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
      type: Key;
    } : {
      type: Key;
      payload: M[Key]
    }
}

export enum ActionTypes {
  SHOW_MENU = 'SHOW_MENU',
  SET_PAGE = "SET_PAGE"
}

export type SettingPayload = {
  [ActionTypes.SHOW_MENU]: AppState;
  [ActionTypes.SET_PAGE]: AppState;
}

export type AppAction = ActionMap<SettingPayload>[keyof ActionMap<SettingPayload>]
