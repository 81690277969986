import { RequestQuote } from './RequestQuote'
import {GiftCard} from './GiftCard'
import { InvoiceGenerator } from './InvoiceGenerator'
import { Portfolio } from './Portfolio'

interface IRightSideProps {
  project: string
}

const RightSide = (props: IRightSideProps) => {
  const project = props.project

  return (
    <>
    {(project === "invoiceGenerator" || project === '') && <InvoiceGenerator /> }
    {(project === "requestQuote") && <RequestQuote /> }
    {project === 'giftCard' && <GiftCard />}
    {project === 'portfolio' && <Portfolio />}
    </>
  )
}

export default RightSide