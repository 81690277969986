import {useState} from "react";
import {SampleProjectComponent} from "./SampleProjectComponent";

export function RequestQuote() {
  const requestaquote = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/requestaquote.png'
  const quote2 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote2.png'
  const quote3 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote3.png'
  const quote4 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote4.png'
  const quote5 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote5.png'
  const quote6 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote6.png'
  const quote7 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote7.png'

  const [image,] = useState(requestaquote)
  const [images,] = useState([quote2, quote3, quote4, quote5, quote6, quote7])

  const [open, setOpen] = useState(false)
  const toggleOpen = () => {
    setOpen(open => !open)
  }

  const detail = (
      <>
        <button onClick={() => toggleOpen()}>
          <p className="font-bold text-sm text-customGreen">What I did in the project: (Click to see full details)</p>
        </button>
        {open &&
          <div className="grid gap-1 mt-2">
            <p className="font-bold text-sm">Server: I used EC2 instance of AWS to host my app.</p>
            <p className="font-bold text-sm">Git: I used gitlab to organize my code.</p>
            <p className="font-bold text-sm">Frontend: I used React Typescript and Polaris Library.</p>
            <p className="text-sm">- For API Calls : I used Use React Query.</p>
            <p className="text-sm">- In order to store global variables, I used useContext and useReducer hooks.</p>
            <p className="text-sm">- In order to store global variables, I used useContext and useReducer hooks.</p>
            <p className="font-bold text-sm">Backend: I used NestJs </p>
            <p className="text-sm">- I set up all of my project with these modules :</p>
            <p className="text-sm">ShopModule,</p>
            <p className="text-sm">ShopifyModule,</p>
            <p className="text-sm">OauthModule,</p>
            <p className="text-sm">StoreModule,</p>
            <p className="text-sm">ProductModule,</p>
            <p className="text-sm">QuoteModule,</p>
            <p className="text-sm">WebhookModule,</p>
            <p className="text-sm">QuoteEntityModule,</p>
            <p className="text-sm">StoreFrontendModule,</p>
            <p className="text-sm"> QuoteAnalysisModule,</p>
            <p className="text-sm">EmailBrandingModule,</p>
          </div>}
      </>
  )

  const TABLE_ROWS = [
    {
      name: "Overview",
      detail: "This is my own commercial application which is going to be published soon.This is a Shopify's app for its merchants that allows theirs customers to request a quote",
    },
    {
      name: "Estimated Cost",
      detail: "Over 4 months",
    },
    {
      name: "Programming Languages",
      detail: "React for Frontend, NestJS for Backend ",
    },
    {
      name: "Database",
      detail: "MySQL - MariaDB + MongoDB. ( First I used MySQL, but then I've decided to use MongoDB together because of its flexibility and it's easy to scale up.",
    },
    {
      name: "Tools and IDE",
      detail: "Visual Code && IntelliJ",
    },
    {
      name: "Some details",
      detail: detail,
    },
    {
      name: "Source Code",
      detail: "Unable to be public because this is an e-commerce product. It will be only shown in my job interview",
    },
    {
      name: "Final Product",
      detail: <a href="" className="underline underline-offset-4 text-customGreen">Request a Quote - will be published
        soon</a>,
    },
  ];

  return (
      <>
        <SampleProjectComponent image={image} images={images} tableRows={TABLE_ROWS}/>
      </>
  );
}
