import HoangDoan from '../../../assets/hoangdoan.png'
import James from '../../../assets/James.png'
import ToAnhTu from '../../../assets/ToANhTu.png'
const Profile = () => {
    
    return (
        <div className="text-justify">
          <p className="mt-2 leading-loose text-gray-400">
            I am a fast learner with strong eagerness to acquire new skills. At Vify, a product-based company, I advanced to a full-stack developer role within just two months. Soon after, I contributed to one of the company&apos;s main projects, the Vify Gift Card, helping it achieve &apos;Built for Shopify&apos; designation.
            I am detail-oriented and committed to writing clean code. My collaboration and teamwork skills are exemplary, enabling me to effectively share knowledge and support colleagues with varying levels of experience—ranging from 3 years in frontend and backend development to 7 years in frontend development.
            My problem-solving skills were demonstrated when I developed a C Sharp .NET Web Application Invoice Generator within just two months. Additionally, my strong communication skills have been recognized through positive feedback from colleagues on LinkedIn following my departure from the company.
          </p>
          <p className="text-xl mt-3 mb-2 text-customGreen">References</p>
          <p className="mt-2 leading-loose text-gray-400">Doan Hoang - Senior Frontend Developer at Vify with over 7 years of experience working with Japanese
            companies </p>
          <img src={HoangDoan} alt="" className="rounded-md"/>
          <p className="mt-3 leading-loose text-gray-400">James - Senior Developer at Vify who has helped the company to build apps from scratch </p>
          <img src={James} alt="" className="rounded-md"/>
          <p className="mt-3 leading-loose text-gray-400">To Anh Tu - Middle Backend Developer at Vify with many years of experience</p>
          <img src={ToAnhTu} alt="" className="rounded-md"/>
        </div>
    )
}

export default Profile
