import {createContext} from "react";
import {initialState} from "./data";
import {IAppContext} from "./types";

const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null
})

export default AppContext
