import {ActionTypes, AppAction, AppState} from "./types";

let returnValue: AppState
let showMenu: boolean
let pageName : string

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case ActionTypes.SHOW_MENU: {
      showMenu = action.payload.showMenu
      returnValue = {...state,showMenu}
      break;
    }
    case ActionTypes.SET_PAGE: {
      pageName = action.payload.page
      returnValue = {...state,page: pageName}
      break;
    }
    default:
      returnValue = state
  }
  return returnValue
}

export default appReducer
