import {useContext, useEffect} from "react";
import AppContext from "../../store/Context";
import {setPageName} from "../../store/actions";
import {mySkills} from "./resume/LeftSide";
import {ResumeTabs} from "./resume/Tabs";
import {ProjectCard} from "./projects/ProjectCard";
import {ContactDetail} from "./contact";

const Introduction = () => {
  const {state, dispatch} = useContext(AppContext)
  const invoiceGeneratorDescription = 'A .NET Web Application that lets merchants create invoices quickly'
  const requestQuoteDescription = 'A shopify app that lets customers request a quote when shopping'
  const invoiceGeneratorTitle = 'INVOICE GENERATOR'
  const requestQuoteTitle = 'REQUEST A QUOTE'
  const giftCardDescription = 'This is a shopify app that lets customers create gift card products'
  const giftCardTitle = 'VIFY GIFT CARD'
  const portfolioDescription = 'Just a simple project using React'
  const portfolioTitle = 'MY PORTFOLIO'
  const invoice04 = 'https://brucenguyenawsbucket.s3.amazonaws.com/invoice/invoice04.png';
  const avatarNew = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/avatarNew.jpg'
  const avatar01 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/avatar01.jpg'
  const quote4 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/quote4.png'
  const giftCard01 = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/giftCard01.png'
  const myPortfolio = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/myPortfolio.jpg'
  useEffect(() => {
    dispatch(setPageName({...state, page: 'introduction'}))
  }, [])

  const introducingMyself = (
      <div className="text-justify">
        <p className="mt-2">
          Firstly, I would say that I am a dedicated professional who strives to enhance both profitability and product
          quality for my company. I maintain long-term focus to meet deadlines and align with corporate goals.
        </p>
        <p className="mt-2">
          My key strength is a strong ability to learn, leveraging my life experience to decide when to seek quick
          answers from others or conduct thorough research independently. I balance the urgency of deadlines with the
          need for deep understanding.
        </p>
        <p className="mt-2">
          Besides, I am a good listener, open-minded, and skilled at recognizing and encouraging
          others&apos; achievements. These traits foster a positive work environment and effective teamwork, crucial
          for the company&apos;s steady growth and individual career advancement.
        </p>
        <p className="mt-2">
          Additionally, my adaptability enables me to thrive in diverse work settings. My life-long endurance and
          open
          mindset support my ability to adjust and excel as a team member.
        </p>
        <p className="mt-2">
          Last but not least, with extensive experience in business and teaching, I can quickly understand
          company
          operations and contribute to developing exceptional applications that meet customer needs.
        </p>
      </div>
  )
  return (
      <>
        <div className=" lg:flex md:flex justify-center" id="aboutMe">
          <div className="max-w-full md:my-5 md:w-4/5 p-2 rounded-md">
            <p className="text-customGreen text-xs tracking-widest flex justify-start md:justify-center lg:justify-center font-quicksand-500 mb-5 md:mb-1">ABOUT
              ME</p>
            <p className="text-lg md:text-2xl uppercase flex justify-start md:justify-center lg:justify-center font-serif font-bold mb-5 md:mb-1 text-white">WHO
              AM I?</p>
            <div className=" lg:gap-4 lg:grid lg:grid-cols-5 lg:h-[400px] xl:h-[400px]">
              <div className="lg:col-span-3 h-full flex flex-col justify-center">
                {introducingMyself}
              </div>
              <div className="mr-2 mt-3 lg:col-span-2 h-full flex flex-col justify-center">
                <img src={avatarNew} alt="" className="rounded-2xl w-full md:h-auto md:w-auto lg:hidden"/>
                <img src={avatar01} alt="avatar01" className="rounded-2xl ws-full lg:h-[400px] hidden md:hidden lg:block object-cover "/>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2 mt-52 md:hidden">
          {mySkills}
        </div>

        <div className="mt-52 md:hidden lg:hidden" id="workExperience">
          <p className="text-customGreen text-xs tracking-widest flex justify-start font-quicksand-500 mb-5">EXPERIENCE</p>
          <p className="text-lg md:text-2xl uppercase flex justify-start font-serif font-bold text-white mb-5">MY WORK
            EXPERIENCE</p>
          <ResumeTabs/>
        </div>

        <div className="mt-52 md:hidden lg:hidden" id="projects">
          <p className="text-customGreen text-xs tracking-widest flex justify-start font-quicksand-500 mb-5">MY WORK</p>
          <p className="text-lg md:text-2xl uppercase flex justify-start font-serif font-bold text-white mb-5">RECENT
            PROJECTS</p>
          <div className="md:hidden lg:hidden mb-3">
            <ProjectCard description={invoiceGeneratorDescription} image={invoice04} title={invoiceGeneratorTitle}/>
          </div>
          <div className="md:hidden lg:hidden mb-3">
            <ProjectCard description={requestQuoteDescription} image={quote4} title={requestQuoteTitle}/>
          </div>
          <div className="md:hidden lg:hidden mb-3">
            <ProjectCard description={giftCardDescription} image={giftCard01} title={giftCardTitle}/>
          </div>
          <div className="md:hidden lg:hidden mb-3">
            <ProjectCard description={portfolioDescription} image={myPortfolio} title={portfolioTitle}/>
          </div>
        </div>

        <div className="mt-52 md:hidden lg:hidden" id="contact">
          <p className="text-customGreen text-xs tracking-widest flex justify-start font-quicksand-500 mb-5">KEEP IN
            TOUCH</p>
          <p className="text-lg md:text-2xl uppercase flex justify-start font-serif font-bold text-white mb-5">CONTACT</p>
          <ContactDetail/>
        </div>
      </>

  )
}

export default Introduction
