import AppContext from "./Context";
import {ReactNode, useReducer} from "react";
import {initialState} from "./data";
import appReducer from "./reducer";

interface IChildrenProps {
children: ReactNode
}

const AppProvider = ({children}: IChildrenProps) => {
  const [state,dispatch] = useReducer(appReducer,initialState)
  
  return (
    <AppContext.Provider value={{state,dispatch}}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
