import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import {useNavigate} from "react-router-dom";


interface IProjectCard {
  title: string,
  image: string,
  description: string
}

export function ProjectCard(props: IProjectCard) {
  const navigate = useNavigate()
  return (
      <Card className="w-full max-w-[26rem] shadow-lg bg-[#232D3F]">
        <CardHeader floated={false} color="white">
          <img
              src={props.image || ''}
              alt="ui/ux review check"
          />
        </CardHeader>
        <CardBody>
          <div className="mb-1 flex items-center justify-between">
            <p className="font-sm text-white font-bold">
              {props.title || ''}
            </p>
          </div>
          <Typography color="white">
            {props.description || ''}
          </Typography>
        </CardBody>
        <CardFooter className="">
          <Button onClick={()=> navigate('/my-projects')} className="bg-blue-gray-700">
            View Details
          </Button>
        </CardFooter>
      </Card>
  );
}
