
export const ContactDetail = () => {
  const EnvelopIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4FD890"
                           className="w-6 h-6 ">
    <path d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"/>
    <path
        d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"/>
  </svg>
  const MapIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4FD890" className="w-6 h-6">
    <path
        d="M15.75 8.25a.75.75 0 0 1 .75.75c0 1.12-.492 2.126-1.27 2.812a.75.75 0 1 1-.992-1.124A2.243 2.243 0 0 0 15 9a.75.75 0 0 1 .75-.75Z"/>
    <path fillRule="evenodd"
          d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM4.575 15.6a8.25 8.25 0 0 0 9.348 4.425 1.966 1.966 0 0 0-1.84-1.275.983.983 0 0 1-.97-.822l-.073-.437c-.094-.565.25-1.11.8-1.267l.99-.282c.427-.123.783-.418.982-.816l.036-.073a1.453 1.453 0 0 1 2.328-.377L16.5 15h.628a2.25 2.25 0 0 1 1.983 1.186 8.25 8.25 0 0 0-6.345-12.4c.044.262.18.503.389.676l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 0 1-1.161.886l-.143.048a1.107 1.107 0 0 0-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 0 1-1.652.928l-.679-.906a1.125 1.125 0 0 0-1.906.172L4.575 15.6Z"
          clipRule="evenodd"/>
  </svg>
  const PhoneIcon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4FD890" className="w-6 h-6">
    <path fillRule="evenodd"
          d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
          clipRule="evenodd"/>
  </svg>


  return (
      <>
        <div className="grid grid-cols-3">
          <div className="square flex items-center justify-center">
            <div className="">
              {EnvelopIcon}
            </div>
          </div>
          <div className="col-span-2 flex items-center text-customGreen">
            developer@brucenguyen.org
          </div>

          <div className="square flex items-center justify-center mt-5">
            <div className="">
              {MapIcon}
            </div>
          </div>
          <div className="col-span-2 flex items-center text-customGreen">
            Adelaide, SA, Australia
          </div>

          <div className="square flex items-center justify-center mt-5">
            <div className="">
              {PhoneIcon}
            </div>
          </div>
          <div className="col-span-2 flex items-center text-customGreen">
            0461449917
          </div>
        </div>
      </>
  )
}