import Cookies from "universal-cookie";
import {createContext, Dispatch, ReactElement, ReactNode, SetStateAction, useContext, useState} from "react";
// import ElementChildrenAttribute = React.JSX.ElementChildrenAttribute;

export interface ITokenProvider {
  token: string,
  setToken: Dispatch<SetStateAction<string>>,
  getToken: () => string,
  removeToken: () => void
}

interface childrenProps {
  children: ReactElement;
}

const defaultValue = {
  token: '',
  setToken: () => '',
  getToken: () => '',
  removeToken: () => null
}

const TokenContext = createContext<ITokenProvider>(defaultValue)

const TokenProvider = ({children}: childrenProps) => {
  const cookies = new Cookies()
  // const token = cookies.get('TOKEN')
  const [token,setToken] = useState<string>(cookies.get('TOKEN'))
  const getToken = (): string => {
    return cookies.get('TOKEN')
  }
  const removeToken = () => {
    return cookies.remove('TOKEN')
  }
  
  const value: ITokenProvider = {
    token,
    setToken,
    getToken,
    removeToken
  }
  const [data,setData] = useState<ITokenProvider>(value)
  return (
    <TokenContext.Provider value={value} >
      {children}
    </TokenContext.Provider>
  )
}

export default TokenProvider

export const useTokenContext = () => useContext(TokenContext)
