import {useState} from "react";
import classNames from "classnames";

interface ILeftSideProps {
  getProject: (projectSelected: string) => void
}

const LeftSide = (props: ILeftSideProps ) => {
  const [activeButton,setActiveButton] = useState('invoiceGenerator')
  const getProject = props.getProject
  const baseClasses = 'text-start block hover:text-white text-gray-300 py-2 px-4 cursor-pointer font-sans text-sm underline-animation'
  const activeClasses = 'activeClasses';
  return (
    <>
      <ul className="flex flex-col">
        <li className="flex-1 mr-2">
          <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'invoiceGenerator'})}
             onClick={() => {
               getProject('invoiceGenerator')
               setActiveButton('invoiceGenerator')
             }}>C SHARP .NET: INVOICE GENERATOR</a>
        </li>
        <li className="flex-1 mr-2">
          <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'requestQuote'})}
             onClick={() => {
               getProject('requestQuote')
               setActiveButton('requestQuote')
             }}>SHOPIFY: REQUEST A QUOTE</a>
        </li>
        <li className="flex-1 mr-2 mt-2">
          <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'giftCard'})}
             onClick={() => {
               getProject('giftCard')
               setActiveButton('giftCard')
             }}>SHOPIFY : GIFT-CARD</a>
        </li>
        <li className="flex-1 mr-2 mt-2">
          <a className={classNames(baseClasses, {[activeClasses]: activeButton === 'portfolio'})}
             onClick={() => {
               getProject('portfolio')
               setActiveButton('portfolio')
             }}>MY PORTFOLIO</a>
        </li>
      </ul>
    </>
  )
}

export default LeftSide
