import {useContext, useState} from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import AppContext from "../../../store/Context";

const MyProjects = () => {
  const {state} = useContext(AppContext)
    const [project, setProject] = useState('')

    const getProject = (projectSelected: string) => {
        setProject(projectSelected)
    }


  return (
    <div className="md:flex justify-center">
      <div className="max-w-full md:my-5 md:w-4/5 rounded-md">
        <div className="md:grid md:grid-cols-3 md:gap-4">
          {state.showMenu && <div className="h-30 md:h-screen sticky top-14 md:hidden  pt-2 pb-4 lg:hidden">
            <LeftSide getProject={getProject}/>
          </div> }
          <div className="h-30 md:h-screen sticky top-14 hidden md:block  pt-2 pb-4 lg:block">
            <LeftSide getProject={getProject}/>
          </div>
          <div className="md:col-span-2 gap-4 md:mr-3 md:mt-2">
            <RightSide project={project}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProjects
