import React, {useContext, useEffect, useState} from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  IconButton,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  LifebuoyIcon,
  PowerIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/solid";
import myAvatar from '../../assets/myAvatar.png'
import AboutMeMenu from "./AboutMeList";
import {useNavigate} from 'react-router-dom'
import {useTokenContext} from "../../context/TokenProvider";
import AppContext from "../../store/Context";
import {toggleShowMenu} from "../../store/actions";
import {ScaleIcon, Squares2X2Icon} from "@heroicons/react/20/solid";
import {useApp} from "../../context/AppProvider";
import {Bars3Icon} from "@heroicons/react/24/outline";

function ProfileMenu() {
  const langtreSOS = 'https://brucenguyenawsbucket.s3.amazonaws.com/Bruce+Nguyen/langtreSOS.png'
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const navigate = useNavigate()
  const data = useTokenContext()

  const signOut = () => {
    data.removeToken()
    const newToken = data.getToken()
    data.setToken(newToken)
    navigate('/')
  }
  const profileMenuItems = [
    {
      label: "My Profile",
      icon: UserCircleIcon,
      href: '#',
      onAction: () => console.log('inbox')
    },
    {
      label: "Edit Profile",
      icon: Cog6ToothIcon,
      href: '#',
      onAction: () => console.log('inbox')
    },
    {
      label: "Inbox",
      icon: InboxArrowDownIcon,
      href: '#',
      onAction: () => console.log('inbox')
    },
    {
      label: "Help",
      icon: LifebuoyIcon,
      href: '#',
      onAction: () => console.log('inbox')
    },
    {
      label: "Sign Out",
      icon: PowerIcon,
      href: '#',
      onAction: signOut
    },
  ];
  return (
      <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
        <MenuHandler>
          <Button
              variant="text"
              color="blue-gray"
              className="hidden items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 lg:ml-auto"
          >
            <Avatar
                variant="circular"
                size="sm"
                alt="tania andrew"
                className="border border-gray-900 p-0.5"
                src={data.token !== undefined ? myAvatar : langtreSOS}
            />
            {data.token !== undefined &&
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3 w-3 transition-transform ${isMenuOpen ? "rotate-180" : ""
                }`}
              />}
          </Button>
        </MenuHandler>
        {data.token && <MenuList className="p-1">
          {profileMenuItems.map(({label, icon, href, onAction}, key) => {
            const isLastItem = key === profileMenuItems.length - 1;
            return (
                <MenuItem
                    key={label}
                    onClick={closeMenu}
                    className={`flex items-center gap-2 rounded ${isLastItem
                        ? "hover:bg-customGreen focus:bg-customGreen active:bg-red-500/10"
                        : ""
                    }`}
                >
                  {React.createElement(icon, {
                    className: `h-4 w-4 ${isLastItem ? "text-red-500" : ""}`,
                    strokeWidth: 2,
                  })}
                  <a href={href}>
                    <Typography
                        as="span"
                        variant="small"
                        className="font-normal"
                        color={isLastItem ? "red" : "inherit"}
                        onClick={onAction}
                    >
                      {label}
                    </Typography>
                  </a>
                </MenuItem>
            );
          })}
        </MenuList>}
      </Menu>
  );
}

const navListItems = [
  {
    label: "Introducing Myself",
    icon: UserCircleIcon,
    href: 'about-me'
  },
  {
    label: "My Resume",
    icon: Square3Stack3DIcon,
    href: 'resume'
  },
  {
    label: "My Projects",
    icon: Squares2X2Icon,
    href: 'my-projects'
  },
  {
    label: "My Community Service Activities",
    icon: ScaleIcon,
    href: 'extra-activities'
  },
];

function AboutMeList() {
  return (
      <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
        <AboutMeMenu/>
      </ul>
  );
}

function NavList() {
  const [activeIndex, setActiveIndex] = useState(0)
  const navigate = useNavigate()

  return (
      <ul className="mt-2 mb-4 flex flex-col md:gap-8 lg:gap-14 lg:mb-0 lg:mt-0 lg:flex-row md:flex-row lg:items-center">
        {navListItems.map(({label, icon, href}, key) => {
          return (
              <button key={key}
                      onClick={(event) => {
                        event.preventDefault()
                        setActiveIndex(key)
                        navigate(href)
                      }}
              >
                <Typography
                    key={key}
                    as="a"
                    href={href}
                    variant="small"
                    className={`font-bold ${activeIndex === key ? 'text-customGreen' : 'text-white'}`}
                >
                  {label}
                </Typography>
              </button>
          )
        })}
      </ul>
  );
}

const MenuBar = () => {
  const app = useApp()
  const data = useTokenContext()
  const navigate = useNavigate()
  const [isNavOpen, setIsNavOpen] = useState(false);
  const {state, dispatch} = useContext(AppContext)
  const [showMenu, setShowMenu] = useState(true)
  const [isAboutMeMenuOpen, setIsAboutMeMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  const handleOpenSideBar = () => {
    app.setShowSideBar(prevState => !prevState)
  }

  React.useEffect(() => {
    window.addEventListener(
        "resize",
        () => window.innerWidth >= 960 && setIsNavOpen(false),
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrollCheck = window.scrollY > 60
      if (isScrollCheck !== isScrolled) {
        setIsScrolled(!isScrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, {passive: true})

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [isScrolled]);

  const toggleMenu = () => {
    setShowMenu((showValue: boolean) => !showMenu)
    dispatch(toggleShowMenu({...state, showMenu: !state.showMenu}))
  };

  return (
      <>
        <Navbar
            className={`flex items-center md:block lg:block bg-menuBarGray ${isScrolled ? "shadow-md" : "shadow-none"} mx-auto max-w-full h-12 lg:rounded rounded-none md:py-3 lg:py-3 px-1 py-1 overflow-scroll border-none sticky top-0 z-[999]`}>
          <IconButton variant="text" size="lg"
                      onClick={handleOpenSideBar}
                      className="md:hidden lg:hidden z-5x0 top-0 opacity-25"
          >
            <Bars3Icon className="h-6 w-6 stroke-2 bg-customGreen"/>
          </IconButton>

          <div className="relative mx-auto justify-evenly flex items-center text-white">
            {state.page !== 'introduction' && state.page !== 'resume' &&
              <Button onClick={() => toggleMenu()} size="sm" variant="outlined"
                      className="block md:hidden cursor-pointer">
                {!state.showMenu ? <p>Show Menu</p> : <p>Hide Menu</p>}
              </Button>}

            <div className="hidden md:block lg:block">
              <NavList/>
            </div>

            {!data.token ?
                <>
                  <Button className="hidden " size="sm" variant="text" onClick={() => navigate('/register')}>
                    <span>Sign Up</span>
                  </Button>
                  <Button className="hidden " size="sm" variant="text" onClick={() => navigate('/login')}>
                    <span>Log In</span>
                  </Button>
                </> : null
            }
            <ProfileMenu/>
          </div>

          <Collapse open={isAboutMeMenuOpen} className="hidden md:block overflow-scroll">
            <AboutMeList/>
          </Collapse>
          <Collapse open={isNavOpen} className="hidden md:block overflow-scroll">
            <NavList/>
          </Collapse>
        </Navbar>

      </>
  );
}

export default MenuBar
